import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2e6a3145"), n = n(), _popScopeId(), n);
const _hoisted_1 = _withScopeId(() => _createElementVNode("div", { class: "modul social-media-leiste" }, [
    _createElementVNode("h2", { style: { "color": "#333", "margin": "10px 0 25px 0", "font-size": "20px" } }, [
        _createElementVNode("span", { class: "fontDbT2Head" }, "Deutsche Bahn interaktiv."),
        _createTextVNode(),
        _createElementVNode("span", { class: "fontDbT2" }, "Reden Sie mit.")
    ]),
    _createElementVNode("ul", null, [
        _createElementVNode("li", { class: "" }, [
            _createElementVNode("a", {
                class: "svg-facebook",
                href: "https://www.facebook.com/DBPersonenverkehr/",
                target: "_blank"
            })
        ]),
        _createElementVNode("li", { class: "" }, [
            _createElementVNode("a", {
                class: "svg-instagram",
                href: "https://www.instagram.com/dbpersonenverkehr/",
                target: "_blank"
            })
        ]),
        _createElementVNode("li", { class: "" }, [
            _createElementVNode("a", {
                class: "svg-twitter",
                href: "https://twitter.com/db_bahn",
                target: "_blank"
            })
        ])
    ])
], -1));
const _hoisted_2 = { class: "footer-image-bar flex c c--highlighted" };
const _hoisted_3 = _withScopeId(() => _createElementVNode("link", {
    rel: "stylesheet",
    type: "text/css",
    href: "/.resources/bahn-classic/webresources/css/footer-image-bar.002793d1bb10c7ec3dbe.css"
}, null, -1));
const _hoisted_4 = _withScopeId(() => _createElementVNode("div", { class: "flex-100 flex-50-m flex-50-l" }, [
    _createElementVNode("span", {
        class: "h3",
        "aria-hidden": "true"
    }, " "),
    _createElementVNode("div", { class: "footer-image-bar__app-stores flex" }, [
        _createElementVNode("figure", { class: "flex-reset footer-image-bar__image-wrapper" }, [
            _createElementVNode("a", {
                class: "flex-reset footer-image-bar__image-link",
                href: "https://www.bahn.de/p/view/service/mobile/db-navigator.shtml",
                title: "DB Navigator"
            }, [
                _createElementVNode("img", {
                    src: "https://assets.static-bahn.de/dam/jcr:86ddfef5-9f95-4fa0-87ae-21722e66e861/bdg_navigator_app.svg",
                    class: "image flex-reset footer-image-bar__image",
                    alt: "icon: DB Navigator App"
                })
            ])
        ]),
        _createElementVNode("figure", { class: "flex-reset footer-image-bar__image-wrapper" }, [
            _createElementVNode("a", {
                class: "flex-reset footer-image-bar__image-link",
                href: "https://gruen.deutschebahn.com/de",
                title: "This is green",
                target: "_blank",
                rel: "noopener noreferrer"
            }, [
                _createElementVNode("img", {
                    src: "https://assets.static-bahn.de/dam/jcr:16eea334-2405-4e25-9551-9353f97ebb6a/bdg_das_ist_gruen.svg",
                    class: "image flex-reset footer-image-bar__image",
                    alt: "Icon: Das ist grün"
                })
            ])
        ])
    ])
], -1));
const _hoisted_5 = {
    key: 0,
    class: "flex-100 flex-50-m flex-50-l flex-left flex-right-m flex-right-l"
};
const _hoisted_6 = _withScopeId(() => _createElementVNode("h3", { class: "flex-left flex-right-m flex-right-l" }, "Payment options", -1));
const _hoisted_7 = _withScopeId(() => _createElementVNode("div", { class: "footer-image-bar__payment flex" }, [
    _createElementVNode("figure", { class: "flex-reset footer-image-bar__image-wrapper" }, [
        _createElementVNode("a", {
            class: "flex-reset footer-image-bar__image-link",
            href: "/en/booking-information/payment-options/paydirekt",
            title: "paydirekt"
        }, [
            _createElementVNode("img", {
                src: "https://assets.static-bahn.de/dam/jcr:c4293587-66f7-4ff4-b3d5-865482033f63/Icon_Footer_paydirekt_68x39.svg",
                class: "image flex-reset footer-image-bar__image",
                alt: "Icon Paydirekt"
            })
        ])
    ]),
    _createElementVNode("figure", { class: "flex-reset footer-image-bar__image-wrapper" }, [
        _createElementVNode("a", {
            class: "flex-reset footer-image-bar__image-link",
            href: "/en/booking-information/payment-options/credit-card",
            title: "credit card"
        }, [
            _createElementVNode("img", {
                src: "https://assets.static-bahn.de/dam/jcr:cd73581b-7d91-40ee-8d43-c861254c2d72/Icon_Footer_credit-card_24x24.svg",
                class: "image flex-reset footer-image-bar__image",
                alt: "Icon Kreditkarte"
            })
        ])
    ]),
    _createElementVNode("figure", { class: "flex-reset footer-image-bar__image-wrapper" }, [
        _createElementVNode("a", {
            class: "flex-reset footer-image-bar__image-link",
            href: "/en/booking-information/payment-options/sofort",
            title: "Klarna"
        }, [
            _createElementVNode("img", {
                src: "https://assets.static-bahn.de/dam/jcr:5259074f-5482-430c-8495-fe1b3b084300/Icon_Footer_Klarna_24x24.svg",
                class: "image flex-reset footer-image-bar__image",
                alt: "Icon Klarna"
            })
        ])
    ]),
    _createElementVNode("figure", { class: "flex-reset footer-image-bar__image-wrapper" }, [
        _createElementVNode("a", {
            class: "flex-reset footer-image-bar__image-link",
            href: "/en/booking-information/payment-options/paypal",
            title: "PayPal"
        }, [
            _createElementVNode("img", {
                src: "https://assets.static-bahn.de/dam/jcr:5c9717a6-1603-468f-982a-b5553e24600d/Icon_Footer_paypal_24x24.svg",
                class: "image flex-reset footer-image-bar__image",
                alt: "Icon Paypal"
            })
        ])
    ]),
    _createElementVNode("figure", { class: "flex-reset footer-image-bar__image-wrapper" }, [
        _createElementVNode("a", {
            class: "flex-reset footer-image-bar__image-link",
            href: "/en/booking-information/payment-options/sepa",
            title: "Sepa"
        }, [
            _createElementVNode("img", {
                src: "https://assets.static-bahn.de/dam/jcr:0cad7773-2a27-4300-896f-d1c60bff49c0/Icon_Footer_SEPA_68x39.svg",
                class: "image flex-reset footer-image-bar__image",
                alt: "Icon Sepa"
            })
        ])
    ])
], -1));
const _hoisted_8 = [
    _hoisted_6,
    _hoisted_7
];
const _hoisted_9 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ion_toolbar = _resolveComponent("ion-toolbar");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        false
            ? (_openBlock(), _createBlock(_component_ion_toolbar, {
                key: 0,
                class: "max_screen",
                id: "footer_toolbar_4"
            }, {
                default: _withCtx(() => [
                    _hoisted_1
                ]),
                _: 1
            }))
            : _createCommentVNode("", true),
        false
            ? (_openBlock(), _createBlock(_component_ion_toolbar, {
                key: 1,
                id: "footer_toolbar_3"
            }))
            : _createCommentVNode("", true),
        false
            ? (_openBlock(), _createBlock(_component_ion_toolbar, {
                key: 2,
                id: "footer_toolbar_2"
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                        _hoisted_3,
                        _hoisted_4,
                        false
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _hoisted_8))
                            : _createCommentVNode("", true)
                    ])
                ]),
                _: 1
            }))
            : _createCommentVNode("", true),
        _createVNode(_component_ion_toolbar, { id: "footer_toolbar_list" }, {
            default: _withCtx(() => [
                _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.impressumData.desktop[_ctx.store.state.lang], (p, i) => {
                        return (_openBlock(), _createElementBlock("li", { key: i }, [
                            _createElementVNode("a", {
                                rel: "nofollow",
                                href: p.link
                            }, _toDisplayString(p.name), 9, _hoisted_9)
                        ]));
                    }), 128))
                ])
            ]),
            _: 1
        })
    ], 64));
}
