import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-12df6740"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "myClass" };
const _hoisted_2 = { style: { "display": "flex", "justify-content": "space-between" } };
const _hoisted_3 = {
    href: "/",
    style: { "display": "inline-block" },
    class: "menu_resp_left"
};
const _hoisted_4 = _withScopeId(() => _createElementVNode("img", {
    alt: "Startseite",
    src: "assets/db-logo.svg"
}, null, -1));
const _hoisted_5 = [
    _hoisted_4
];
const _hoisted_6 = { style: { "display": "inline-block" } };
const _hoisted_7 = _withScopeId(() => _createElementVNode("img", {
    id: "logoBahnBusResMen",
    src: "assets/logo_bahnbusiness.jpg"
}, null, -1));
const _hoisted_8 = [
    _hoisted_7
];
const _hoisted_9 = { style: { "display": "inline-flex" } };
const _hoisted_10 = {
    style: { "padding": "18px 2px 14px 16px" },
    class: "breadCrumbEl"
};
const _hoisted_11 = {
    class: "breadCrumbEl",
    style: { "margin-top": "18px", "margin-bottom": "10px" }
};
const _hoisted_12 = {
    key: 0,
    class: "homeBreadCrumb homeBreadCrumbFett breadCrumbEl"
};
const _hoisted_13 = { key: 1 };
const _hoisted_14 = {
    key: 0,
    class: "homeBreadCrumb homeBreadCrumbFett breadCrumbEl"
};
const _hoisted_15 = ["onClick"];
const _hoisted_16 = { class: "mobilMenuDataCon" };
const _hoisted_17 = { key: 0 };
const _hoisted_18 = { key: 0 };
const _hoisted_19 = { key: 1 };
const _hoisted_20 = { key: 2 };
const _hoisted_21 = { key: 3 };
const _hoisted_22 = ["href"];
const _hoisted_23 = ["onClick"];
const _hoisted_24 = {
    key: 1,
    class: "menMoItemLink mobilEl mobilElBordTop"
};
const _hoisted_25 = ["href"];
const _hoisted_26 = {
    key: 1,
    class: "mobilSearchCon"
};
const _hoisted_27 = ["action"];
const _hoisted_28 = ["value"];
const _hoisted_29 = ["value"];
const _hoisted_30 = _withScopeId(() => _createElementVNode("button", {
    type: "submit",
    class: "search-form__search-icon"
}, null, -1));
const _hoisted_31 = { key: 2 };
const _hoisted_32 = { key: 0 };
const _hoisted_33 = {
    key: 1,
    id: "mobilLanguage",
    class: "mobilMenuCon"
};
const _hoisted_34 = {
    key: 3,
    class: "mobilElOther mobilElBordBot"
};
const _hoisted_35 = ["href"];
const _hoisted_36 = {
    key: 4,
    class: "mobilElOther mobilElBordBot"
};
const _hoisted_37 = ["href"];
const _hoisted_38 = {
    key: 5,
    class: "mobilElOther mobilElBordBot"
};
const _hoisted_39 = ["href"];
const _hoisted_40 = { id: "contentAll" };
const _hoisted_41 = { class: "content_all max_screen" };
const _hoisted_42 = {
    key: 0,
    class: "carouselDbCon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ion_toolbar = _resolveComponent("ion-toolbar");
    const _component_ion_input = _resolveComponent("ion-input");
    const _component_ion_content = _resolveComponent("ion-content");
    const _component_ion_menu = _resolveComponent("ion-menu");
    const _component_template_header_menu = _resolveComponent("template-header-menu");
    const _component_template_resp_menu = _resolveComponent("template-resp-menu");
    const _component_router_view = _resolveComponent("router-view");
    const _component_carousel_db = _resolveComponent("carousel-db");
    const _component_picture_table = _resolveComponent("picture-table");
    const _component_template_impressum = _resolveComponent("template-impressum");
    const _component_ion_page = _resolveComponent("ion-page");
    const _component_IonApp = _resolveComponent("IonApp");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_IonApp, null, {
            default: _withCtx(() => [
                _createVNode(_component_ion_menu, {
                    ref: "menuRef",
                    menu_id: "first",
                    swipeGesture: "true",
                    id: "resp_menu",
                    side: "end",
                    "content-id": "main-content",
                    type: "overlay"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_ion_content, { id: "content_menu_mobile" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_ion_toolbar, {
                                    class: "max_screen",
                                    style: { "border-bottom": "8px solid rgb(240, 20, 20)", "background": "white" }
                                }, {
                                    default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_2, [
                                            _createElementVNode("div", null, [
                                                _withDirectives(_createElementVNode("a", _hoisted_3, _hoisted_5, 512), [
                                                    [_vShow, _ctx.menuIndex.length == 0 && !_ctx.showLanguage]
                                                ]),
                                                _withDirectives(_createElementVNode("div", _hoisted_6, _hoisted_8, 512), [
                                                    [_vShow, _ctx.menuIndex.length == 0 && !_ctx.showLanguage]
                                                ]),
                                                _withDirectives(_createElementVNode("div", {
                                                    class: "menuMobBack",
                                                    onClick: _cache[0] || (_cache[0] = ($event) => {
                                                        _ctx.menuIndex.pop();
                                                        _ctx.retrieveMenuKnot();
                                                        _ctx.setShowLanguage(false);
                                                    })
                                                }, _toDisplayString(_ctx.headerTranslation[_ctx.store.state.lang].zurueck), 513), [
                                                    [_vShow, _ctx.menuIndex.length > 0 || _ctx.showLanguage]
                                                ])
                                            ]),
                                            _createElementVNode("div", {
                                                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.closeMenu())),
                                                class: "head__nav-menu--close"
                                            }, [
                                                _createElementVNode("span", null, _toDisplayString(_ctx.menuTranslation[_ctx.store.state.lang]), 1)
                                            ])
                                        ])
                                    ]),
                                    _: 1
                                }),
                                _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("div", _hoisted_10, [
                                        _createElementVNode("a", null, [
                                            _createElementVNode("img", {
                                                class: _normalizeClass({
                                                    mobilMenHomeButton: _ctx.showLanguage || _ctx.menuIndex.length > 0,
                                                }),
                                                onClick: _cache[2] || (_cache[2] = ($event) => {
                                                    _ctx.showLanguage = false;
                                                    _ctx.menuIndex = [];
                                                    _ctx.retrieveMenuKnot();
                                                }),
                                                style: { "height": "20px", "width": "18px", "max-width": "none" },
                                                alt: "Startseite",
                                                src: "assets/home.svg"
                                            }, null, 2)
                                        ])
                                    ]),
                                    _createElementVNode("div", _hoisted_11, [
                                        (_ctx.showLanguage)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.mobilData.language.label), 1))
                                            : _createCommentVNode("", true),
                                        (!_ctx.showLanguage && _ctx.menuIndex.length > 0)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuIndex, (m, i) => {
                                                    return (_openBlock(), _createElementBlock("div", {
                                                        key: i,
                                                        class: "breadCrumbEl"
                                                    }, [
                                                        (_ctx.menuIndex.length == i + 1)
                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.retrieveBreadCrumbValue(i)), 1))
                                                            : (_openBlock(), _createElementBlock("div", {
                                                                key: 1,
                                                                onClick: ($event) => (_ctx.breadCrumbClick(i)),
                                                                class: "homeBreadCrumb homeBreadCrumbNormal breadCrumbEl"
                                                            }, _toDisplayString(_ctx.retrieveBreadCrumbValue(i)), 9, _hoisted_15))
                                                    ]));
                                                }), 128))
                                            ]))
                                            : _createCommentVNode("", true)
                                    ])
                                ]),
                                _createElementVNode("div", _hoisted_16, [
                                    (!_ctx.showLanguage)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                            false
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.mobilData), 1))
                                                : _createCommentVNode("", true),
                                            false
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.mobilDataCalc), 1))
                                                : _createCommentVNode("", true),
                                            false
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.menuIndex), 1))
                                                : _createCommentVNode("", true),
                                            (_ctx.menuIndex.length > 0)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                                    _createElementVNode("a", {
                                                        class: "menMoOverview menMoItemLink mobilEl mobilElBordTop",
                                                        href: _ctx.overviewData.link
                                                    }, "Übersicht: " + _toDisplayString(_ctx.overviewData.title), 9, _hoisted_22)
                                                ]))
                                                : _createCommentVNode("", true),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mobilDataCalc, (m, i) => {
                                                return (_openBlock(), _createElementBlock("div", { key: i }, [
                                                    (m.children.length > 0)
                                                        ? (_openBlock(), _createElementBlock("div", {
                                                            key: 0,
                                                            class: "menMoItem mobilEl mobilElBordTop",
                                                            onClick: ($event) => {
                                                                _ctx.menuIndex.push(i);
                                                                _ctx.retrieveMenuKnot();
                                                            }
                                                        }, [
                                                            _createElementVNode("span", null, _toDisplayString(m.title), 1)
                                                        ], 8, _hoisted_23))
                                                        : (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                                            _createElementVNode("a", {
                                                                href: m.link
                                                            }, [
                                                                _createElementVNode("span", null, _toDisplayString(m.title), 1)
                                                            ], 8, _hoisted_25)
                                                        ]))
                                                ]));
                                            }), 128))
                                        ]))
                                        : _createCommentVNode("", true),
                                    (_ctx.menuIndex.length == 0 && !_ctx.showLanguage)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                            _createElementVNode("form", {
                                                action: _ctx.headerTranslation[_ctx.store.state.lang].actionLink,
                                                autocomplete: "off",
                                                method: "get",
                                                "accept-charset": "UTF-8",
                                                class: "search__form-compact fontDbT5"
                                            }, [
                                                _createElementVNode("input", {
                                                    type: "hidden",
                                                    name: "s",
                                                    value: _ctx.headerTranslation[_ctx.store.state.lang].hiddenS
                                                }, null, 8, _hoisted_28),
                                                _createElementVNode("input", {
                                                    type: "hidden",
                                                    name: "l",
                                                    value: _ctx.headerTranslation[_ctx.store.state.lang].hiddenL
                                                }, null, 8, _hoisted_29),
                                                _createVNode(_component_ion_input, {
                                                    id: "search-input",
                                                    type: "search",
                                                    name: "q",
                                                    class: "search__form-input-compact fontDbT5",
                                                    autocomplete: "off",
                                                    value: "",
                                                    placeholder: _ctx.headerTranslation[_ctx.store.state.lang].suchen
                                                }, null, 8, ["placeholder"]),
                                                _hoisted_30
                                            ], 8, _hoisted_27)
                                        ]))
                                        : _createCommentVNode("", true),
                                    false
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                                            (_ctx.menuIndex.length == 0 && !_ctx.showLanguage)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                                    _createElementVNode("div", {
                                                        onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.setShowLanguage(true))),
                                                        class: "fontDbT4 mobilLang menMoItem mobilElBordTop mobilElBordBot"
                                                    }, [
                                                        _createElementVNode("span", null, _toDisplayString(_ctx.mobilData.language.label), 1)
                                                    ])
                                                ]))
                                                : _createCommentVNode("", true),
                                            (_ctx.showLanguage)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                                    _withDirectives(_createElementVNode("div", {
                                                        onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.setLangValue('cs'))),
                                                        class: "fontDbT4 mobilEl mobilElBordTop"
                                                    }, " Český ", 512), [
                                                        [_vShow, _ctx.store.state.lang !== 'cs']
                                                    ]),
                                                    _withDirectives(_createElementVNode("div", {
                                                        onClick: _cache[5] || (_cache[5] = ($event) => (_ctx.setLangValue('da'))),
                                                        class: "fontDbT4 mobilEl mobilElBordTop"
                                                    }, " Dansk ", 512), [
                                                        [_vShow, _ctx.store.state.lang !== 'da']
                                                    ]),
                                                    _withDirectives(_createElementVNode("div", {
                                                        onClick: _cache[6] || (_cache[6] = ($event) => (_ctx.setLangValue('de'))),
                                                        class: "fontDbT4 mobilEl mobilElBordTop"
                                                    }, " Deutsch ", 512), [
                                                        [_vShow, _ctx.store.state.lang !== 'de']
                                                    ]),
                                                    _withDirectives(_createElementVNode("div", {
                                                        onClick: _cache[7] || (_cache[7] = ($event) => (_ctx.setLangValue('en'))),
                                                        class: "fontDbT4 mobilEl mobilElBordTop"
                                                    }, " English ", 512), [
                                                        [_vShow, _ctx.store.state.lang !== 'en']
                                                    ]),
                                                    _withDirectives(_createElementVNode("div", {
                                                        onClick: _cache[8] || (_cache[8] = ($event) => (_ctx.setLangValue('es'))),
                                                        class: "fontDbT4 mobilEl mobilElBordTop"
                                                    }, " Español ", 512), [
                                                        [_vShow, _ctx.store.state.lang !== 'es']
                                                    ]),
                                                    _withDirectives(_createElementVNode("div", {
                                                        onClick: _cache[9] || (_cache[9] = ($event) => (_ctx.setLangValue('fr'))),
                                                        class: "fontDbT4 mobilEl mobilElBordTop"
                                                    }, " Français ", 512), [
                                                        [_vShow, _ctx.store.state.lang !== 'fr']
                                                    ]),
                                                    _withDirectives(_createElementVNode("div", {
                                                        onClick: _cache[10] || (_cache[10] = ($event) => (_ctx.setLangValue('it'))),
                                                        class: "fontDbT4 mobilEl mobilElBordTop"
                                                    }, " Italiano ", 512), [
                                                        [_vShow, _ctx.store.state.lang !== 'it']
                                                    ]),
                                                    _withDirectives(_createElementVNode("div", {
                                                        onClick: _cache[11] || (_cache[11] = ($event) => (_ctx.setLangValue('nl'))),
                                                        class: "fontDbT4 mobilEl mobilElBordTop"
                                                    }, " Nederlands ", 512), [
                                                        [_vShow, _ctx.store.state.lang !== 'nl']
                                                    ]),
                                                    _withDirectives(_createElementVNode("div", {
                                                        onClick: _cache[12] || (_cache[12] = ($event) => (_ctx.setLangValue('pl'))),
                                                        class: "fontDbT4 mobilEl mobilElBordTop mobilElBordBot"
                                                    }, " Polski ", 512), [
                                                        [_vShow, _ctx.store.state.lang !== 'pl']
                                                    ])
                                                ]))
                                                : _createCommentVNode("", true)
                                        ]))
                                        : _createCommentVNode("", true),
                                    (_ctx.menuIndex.length == 0 && !_ctx.showLanguage)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                            _createElementVNode("a", {
                                                href: _ctx.mobilData.meta[0].link
                                            }, _toDisplayString(_ctx.mobilData.meta[0].title), 9, _hoisted_35)
                                        ]))
                                        : _createCommentVNode("", true),
                                    (_ctx.menuIndex.length == 0 && !_ctx.showLanguage)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                            _createElementVNode("a", {
                                                href: _ctx.mobilData.meta[1].link
                                            }, _toDisplayString(_ctx.mobilData.meta[1].title), 9, _hoisted_37)
                                        ]))
                                        : _createCommentVNode("", true),
                                    (_ctx.store.state.lang == 'de' &&
                                        _ctx.menuIndex.length == 0 &&
                                        !_ctx.showLanguage &&
                                        _ctx.mobilData.meta[2])
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                                            (_ctx.mobilData.meta[2])
                                                ? (_openBlock(), _createElementBlock("a", {
                                                    key: 0,
                                                    href: _ctx.mobilData.meta[2].link
                                                }, _toDisplayString(_ctx.mobilData.meta[2].title), 9, _hoisted_39))
                                                : _createCommentVNode("", true)
                                        ]))
                                        : _createCommentVNode("", true)
                                ])
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 512),
                _createVNode(_component_ion_page, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_ion_content, {
                            class: "max_screen_content",
                            fullscreen: "true",
                            id: "main-content"
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_40, [
                                    _createVNode(_component_template_header_menu),
                                    _createVNode(_component_template_resp_menu),
                                    _createElementVNode("div", _hoisted_41, [
                                        _createVNode(_component_router_view),
                                        false
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                                                _createVNode(_component_carousel_db)
                                            ]))
                                            : _createCommentVNode("", true),
                                        false
                                            ? (_openBlock(), _createBlock(_component_picture_table, { key: 1 }))
                                            : _createCommentVNode("", true),
                                        _createVNode(_component_template_impressum)
                                    ])
                                ])
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                })
            ]),
            _: 1
        })
    ]));
}
