import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["href"];
const _hoisted_2 = { class: "sl_cont_flex" };
const _hoisted_3 = { class: "sl_left" };
const _hoisted_4 = ["src"];
const _hoisted_5 = { class: "sl_right" };
const _hoisted_6 = { class: "sl_heading" };
const _hoisted_7 = ["innerHTML"];
const _hoisted_8 = _createElementVNode("br", null, null, -1);
const _hoisted_9 = {
    key: 0,
    class: "fontDbT4 nav__link_rotate sl_link_bottom"
};
const _hoisted_10 = {
    key: 1,
    class: "fontDbT4 nav__link sl_link_bottom"
};
const _hoisted_11 = { class: "sl_cont_flex" };
const _hoisted_12 = _createElementVNode("div", { class: "sl_left" }, null, -1);
const _hoisted_13 = {
    key: 0,
    class: "sl_right_second"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ion_slide = _resolveComponent("ion-slide");
    const _component_ion_slides = _resolveComponent("ion-slides");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.sliderData.sl.length > 1)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.prevSlide())),
                class: "swiper-button-prev max_screen"
            }))
            : _createCommentVNode("", true),
        (_ctx.viewEntered)
            ? (_openBlock(), _createBlock(_component_ion_slides, {
                key: 1,
                style: { "max-width": "938px" },
                class: "sliderDB",
                ref: "ionSlider",
                pager: "true",
                options: _ctx.slideOpts
            }, {
                default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliderData.sl, (sl, i) => {
                        return (_openBlock(), _createBlock(_component_ion_slide, { key: i }, {
                            default: _withCtx(() => [
                                _createElementVNode("a", {
                                    class: "slideDB_link",
                                    target: "_blank",
                                    href: sl.slLink
                                }, [
                                    _createElementVNode("div", _hoisted_2, [
                                        _createElementVNode("div", _hoisted_3, [
                                            _createElementVNode("img", {
                                                src: sl.imgSrc,
                                                class: "sl_image"
                                            }, null, 8, _hoisted_4)
                                        ]),
                                        _createElementVNode("div", _hoisted_5, [
                                            _createElementVNode("div", _hoisted_6, _toDisplayString(sl.heading), 1),
                                            _createElementVNode("div", {
                                                innerHTML: sl.text,
                                                class: "fontDbT4 sl_text"
                                            }, null, 8, _hoisted_7),
                                            _hoisted_8,
                                            (sl.textLinkExtern)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(sl.textLink), 1))
                                                : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(sl.textLink), 1))
                                        ])
                                    ]),
                                    _createElementVNode("div", _hoisted_11, [
                                        _hoisted_12,
                                        false
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_13))
                                            : _createCommentVNode("", true)
                                    ])
                                ], 8, _hoisted_1)
                            ]),
                            _: 2
                        }, 1024));
                    }), 128))
                ]),
                _: 1
            }, 8, ["options"]))
            : _createCommentVNode("", true),
        (_ctx.sliderData.sl.length > 1)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "swiper-button-next max_screen",
                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.nextSlide()))
            }))
            : _createCommentVNode("", true)
    ], 64));
}
