import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { href: "https://20-fahrten.bahnbusiness.de/" };
const _hoisted_2 = _createElementVNode("img", {
    target: "_blank",
    src: "assets/pictureTable/banner_20fahrten_710_355.jpg"
}, null, -1);
const _hoisted_3 = _createTextVNode("20-Fahrten-Ticket für Geschäftskunden");
const _hoisted_4 = _createElementVNode("strong", null, "Für jeden Pendler die richtige Karte", -1);
const _hoisted_5 = _createTextVNode(" um komfortabel mit Bahn und Bus ins Büro zu kommen. ");
const _hoisted_6 = _createTextVNode("Zur Buchung");
const _hoisted_7 = { href: "https://bcbp.db-app.de/" };
const _hoisted_8 = _createElementVNode("img", {
    target: "_blank",
    src: "assets/pictureTable/BahnCard_BusinesPlaner.jpg"
}, null, -1);
const _hoisted_9 = _createTextVNode("BahnCard Business Planer");
const _hoisted_10 = _createTextVNode(" Wann lohnt sich welche ");
const _hoisted_11 = _createElementVNode("strong", null, "BahnCard Business", -1);
const _hoisted_12 = _createTextVNode(" für Sie? ");
const _hoisted_13 = _createTextVNode("Jetzt berechnen");
const _hoisted_14 = { href: "https://bcbp.db-app.de/prognose/" };
const _hoisted_15 = _createElementVNode("img", {
    target: "_blank",
    src: "assets/pictureTable/bc100_blank_710_355.jpg"
}, null, -1);
const _hoisted_16 = _createTextVNode("BahnCard 100 Ihr Prognosetool");
const _hoisted_17 = _createTextVNode(" So einfach erstellen Sie eine ");
const _hoisted_18 = _createElementVNode("strong", null, "Prognoseberechnung für Ihre BahnCard 100", -1);
const _hoisted_19 = _createTextVNode(". ");
const _hoisted_20 = _createTextVNode("Jetzt berechnen");
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ion_card_title = _resolveComponent("ion-card-title");
    const _component_ion_card_header = _resolveComponent("ion-card-header");
    const _component_ion_card_content = _resolveComponent("ion-card-content");
    const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle");
    const _component_ion_card = _resolveComponent("ion-card");
    const _component_ion_col = _resolveComponent("ion-col");
    const _component_ion_row = _resolveComponent("ion-row");
    const _component_ion_grid = _resolveComponent("ion-grid");
    return (_ctx.viewEntered)
        ? (_openBlock(), _createBlock(_component_ion_grid, {
            key: 0,
            class: "pictureTableGrid"
        }, {
            default: _withCtx(() => [
                _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_ion_col, {
                            size: "12",
                            sizeMd: "4"
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_ion_card, null, {
                                    default: _withCtx(() => [
                                        _createElementVNode("a", _hoisted_1, [
                                            _hoisted_2,
                                            _createVNode(_component_ion_card_header, null, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_ion_card_title, null, {
                                                        default: _withCtx(() => [
                                                            _hoisted_3
                                                        ]),
                                                        _: 1
                                                    })
                                                ]),
                                                _: 1
                                            }),
                                            _createVNode(_component_ion_card_content, null, {
                                                default: _withCtx(() => [
                                                    _hoisted_4,
                                                    _hoisted_5
                                                ]),
                                                _: 1
                                            }),
                                            _createVNode(_component_ion_card_subtitle, { class: "nav__link picTableLink" }, {
                                                default: _withCtx(() => [
                                                    _hoisted_6
                                                ]),
                                                _: 1
                                            })
                                        ])
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_ion_col, {
                            size: "12",
                            sizeMd: "4"
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_ion_card, null, {
                                    default: _withCtx(() => [
                                        _createElementVNode("a", _hoisted_7, [
                                            _hoisted_8,
                                            _createVNode(_component_ion_card_header, null, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_ion_card_title, null, {
                                                        default: _withCtx(() => [
                                                            _hoisted_9
                                                        ]),
                                                        _: 1
                                                    })
                                                ]),
                                                _: 1
                                            }),
                                            _createVNode(_component_ion_card_content, null, {
                                                default: _withCtx(() => [
                                                    _hoisted_10,
                                                    _hoisted_11,
                                                    _hoisted_12
                                                ]),
                                                _: 1
                                            }),
                                            _createVNode(_component_ion_card_subtitle, { class: "nav__link picTableLink" }, {
                                                default: _withCtx(() => [
                                                    _hoisted_13
                                                ]),
                                                _: 1
                                            })
                                        ])
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_ion_col, {
                            size: "12",
                            sizeMd: "4"
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_ion_card, null, {
                                    default: _withCtx(() => [
                                        _createElementVNode("a", _hoisted_14, [
                                            _hoisted_15,
                                            _createVNode(_component_ion_card_header, null, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_ion_card_title, null, {
                                                        default: _withCtx(() => [
                                                            _hoisted_16
                                                        ]),
                                                        _: 1
                                                    })
                                                ]),
                                                _: 1
                                            }),
                                            _createVNode(_component_ion_card_content, null, {
                                                default: _withCtx(() => [
                                                    _hoisted_17,
                                                    _hoisted_18,
                                                    _hoisted_19
                                                ]),
                                                _: 1
                                            }),
                                            _createVNode(_component_ion_card_subtitle, { class: "nav__link picTableLink" }, {
                                                default: _withCtx(() => [
                                                    _hoisted_20
                                                ]),
                                                _: 1
                                            })
                                        ])
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }))
        : _createCommentVNode("", true);
}
