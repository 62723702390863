import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { VueWindowSizePlugin } from "vue-window-size/option-api";
import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { IonicVue } from "@ionic/vue";
import "@ionic/vue/css/core.css";
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
import "./theme/variables.css";
import "./theme/typography.css";
import "./theme/style.css";
import "./theme/icons.css";
import { store, key } from "./store";
const app = createApp(App).use(store, key)
    .use(IonicVue)
    .use(router)
    .use(VueWindowSizePlugin)
    .use(VueFlatPickr);
router.isReady().then(() => {
    app.mount("#app");
});
